"use client"

import { useEffect } from 'react';
import { useAuthContext } from '../../../data/context/authContext';

/**
 * If a user is logged in then we run the function passed in
 * @param {function} callback  - call back to run if someone is logged in.
 * @param {array} dependencies - array of properties to check, this extends and still had the isLoggedIn in the dependencies.
 * @returns
 */
const useIfLoggedIn = (callback: () => void, dependencies = []) => {
    const { checkLoggedIn, isLoggedIn } = useAuthContext();

    /** We need this to run and check the Logged in state because it can change across browser tabs if people have multiple tabs open */
    useEffect(() => {
        const funcToRun = typeof callback === 'function' ? callback : () => {};

        if (checkLoggedIn()) {
            funcToRun();
        }
    }, [...dependencies, isLoggedIn]);

    return null;
};

export default useIfLoggedIn;
