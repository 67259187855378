"use client";

import auth from '../../modules/auth/auth';
import useIfLoggedIn from '../../modules/auth/hooks/useIfLoggedin';

const RedirectIfLoggedin = () => {
    useIfLoggedIn(auth.handleLogin);

    return null;
};

export default RedirectIfLoggedin;
