import sendSentryMessage from '@/lib/sentry/helpers/sendSentryMessage';

import { API_URL, SITE_URL } from '../config/config';
import { replaceTrailingSlash } from './text/textFormatting';

const allowedOrigins = [replaceTrailingSlash(API_URL as string), SITE_URL, SITE_URL];

export const sendInsecureOriginSentryMessage = (process: string, origin: string) => {
    sendSentryMessage(`Iframe has received messages from an insecure origin during ${process}.`, (scope) => {
        scope.setExtra('Origin of message', origin);
        scope.setExtra('Allowed origins', allowedOrigins.join(', '));
    });
};

const checkPostMessageOriginIsSecure = (origin: string) => {
    const normalisedOrigin = replaceTrailingSlash(origin);
    return allowedOrigins.includes(normalisedOrigin);
};

export default checkPostMessageOriginIsSecure;
